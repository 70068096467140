import React, { PureComponent, RefObject } from 'react'

import styled from 'styled-components'
import renderChart from './chart.js'

import { Colors } from '../../constant'
import { Box100 } from '../style'
import { IChartProps } from './interface'

const DarkGreyLegend = styled.div`
  color: ${Colors.DarkGrey};
  font-size: 18px;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
`

class BubbleChart extends PureComponent<IChartProps> {
  public myRef: RefObject<any>
  public chart: any

  constructor (props: IChartProps) {
    super(props)
    this.myRef = React.createRef()
  }

  public componentDidMount () {
    if (this.props.data.length === 0) {
      return
    }

    setTimeout(() => {
      if (!this.myRef.current) {
        return
      }

      this.chart = new renderChart(this.myRef.current, this.props.data, this.props.colors)
      this.chart.draw(this.props.vname)
    }, this.props.timeout)
  }

  public render () {
    return (
      <>
        <Box100 ref={this.myRef}></Box100>
        {this.props.showLegend ? (
          <DarkGreyLegend>*1 dot = {this.props.deathperdot} deaths</DarkGreyLegend>
        ) : ''}
      </>
    )
  }
}

export default BubbleChart
