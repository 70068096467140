export default function renderChart(el, data, max, colors, showLegend) {
  var d3 = window.d3
  var textHeight = 20;
  var duration = 800;
  var delay = 100;
  // set the dimensions and margins of the graph
  var margin = {top: 30, right: 0, bottom: 30, left: 0};

  if(showLegend) {
    margin.left = 20;
    margin.bottom += 40;
  }

  var width = el.clientWidth - margin.left - margin.right,
  height = el.clientHeight - margin.top - margin.bottom;

  d3.select(el).selectAll("svg").remove()

  // append the svg object to the body of the page
  var svg = d3.select(el)
    .append("svg")
    .attr("width", el.clientWidth)
    .attr("height", el.clientHeight)
    .append("g")
    .attr("transform",
      "translate(" + margin.left + "," + margin.top + ")");

  // X axis
  var x = d3.scale.ordinal()
    .rangeRoundBands([ margin.left, width ], 0.2)
    .domain(data.map(function(d) { return d.year; }));

  if(x.rangeBand() < 40) {
    height -= 40;
  }

  // Axis
  var xAxis = d3.svg.axis()
      .scale(x)
      .orient("bottom")
  
  var ticks = svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)

  if(x.rangeBand() < 40) {
    ticks.selectAll("text")	
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-75)");
  }
    
  svg.selectAll(".domain")
    .attr("stroke", "none")
    .attr("fill", "none")
    
  svg.selectAll(".tick line")
    .attr("stroke", "none")

  // Add Y axis
  var y = d3.scale.linear()
    .domain([0, max])
    .range([ height, 0]);
  
  // svg.append("g")
  //   .call(d3.axisLeft(y));

  // Create Event Handlers for mouse
  function handleMouseEvent(el, d, isOver) {  // Add interactivity
    if(!showLegend) {
      return;
    }

    var index = isOver ? 1 : 0
    // Use D3 to select element, change color and size
    d3.select(el).attr("fill", colors[index]);
    d3.selectAll("#t" + d.year).style("opacity", index)
  }

  // Bars
  svg.selectAll("mybar")
    .data(data)
    .enter()
    .append("rect")
      .attr("x", function(d) { return x(d.year); })
      .attr("width", x.rangeBand())
      .attr("fill", colors[0])
      // no bar at the beginning thus:
      .attr("height", function(d) { return height - y(0); }) // always equal to 0
      .attr("y", function(d) { return y(0); })
      .on("mouseover", function(d) { handleMouseEvent(this, d, true) })
      .on("mouseout", function(d) { handleMouseEvent(this, d, false) })

  // Animation
  svg.selectAll("rect")
    .transition()
    .duration(duration)
    .attr("y", function(d) { return y(d.deaths); })
    .attr("height", function(d) { return height - y(d.deaths); })
    .delay(function(d,i) { return(i * delay) })

  // Texts
  svg.selectAll(".text")  		
	  .data(data)
	  .enter()
	  .append("text")
    .attr("class","label")
    .attr("id", function(d) { return "t" + d.year })
	  .attr("x", function(d) { return x(d.year) + x.rangeBand() / 2 ; })
    .attr("y", function(d) { return y(0) - textHeight })
    .style("opacity", showLegend ? 0 : 1)
    .attr("text-anchor","middle")
	  .attr("dy", ".75em")
    .text(function(d) { return d.deaths; });  
  
  svg.selectAll(".label")
    .transition()
    .duration(800)
    .attr("y", function(d) { return y(d.deaths) - textHeight })
    .delay(function(d,i) { return (i * delay) })

  if(!showLegend) {
    return
  }

  // now add titles to the axes
  svg.append("text")
    .style("font-size", "20px")
    .style("font-weight", "bold")
    .attr("text-anchor", "middle")  // this makes it easy to centre the text as the transform is applied to the anchor
    .attr("transform", "translate(0," + (height/2) + ")rotate(-90)")  // text is drawn off the screen top left, move down and out and rotate
    .text("Cases");

  svg.append("text")
    .style("font-size", "20px")
    .style("font-weight", "bold")
    .attr("text-anchor", "middle")  // this makes it easy to centre the text as the transform is applied to the anchor
    .attr("transform", "translate("+ (width/2) +","+ (height + margin.bottom) + ")")  // centre below axis
    .text("Year");
}