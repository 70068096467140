import React from 'react'
import HBarChart from '../../common/hbarchart'
import { Colors, sort } from '../../constant'
import connectStore from '../../store/connect'
import Section from '../section'
import { Highlight } from '../section/style'
import { IData, IListElement, ISheetData } from './interface'

class Section8 extends Section {
  public get sheetIndex () {
    return 8
  }

  public processSheetData (sheetData: ISheetData[]): IData {
    const list: IListElement[] = sheetData.map((d: ISheetData) => {
      return {
        county: d.county,
        deaths: parseInt(d.deaths, 10)
      }
    })

    sort(list, 'deaths', true)

    const max = parseInt(sheetData[0].max, 10)
    const total = parseInt(sheetData[0].total, 10)
    return { list, max, total }
  }

  public renderTitle () {
    const data: IData = this.getData()

    return (
      <div>
        <Highlight className='box-highlight'> {data.list[0].county} </Highlight>
        <span>county accounts for the most deaths by Police and enforced disappearances</span>
      </div>
    )
  }

  public renderChart () {
    const data: IData = this.getData()
    const colors = [Colors.Red]
    if (this.props.isSection) {
      colors.push(Colors.DarkGrey)
    } else {
      colors.push(Colors.Black)
    }
    colors.push(Colors.DarkGrey)

    return (
      <HBarChart
        colors={colors}
        data={data.list}
        max={data.max}
        timeout={this.getTimeOut()}
        showLegend={!this.props.isSection}
      />
    )
  }
}

export default connectStore(Section8)
