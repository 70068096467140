import React, { PureComponent, RefObject } from 'react'
import Home from '../home'

import { MAX_SECTION_REF } from '../../constant'
import './index.css'
import './responsive.css'

import Section2 from '../section2'
import Section3 from '../section3'
import Section4 from '../section4'
import Section5 from '../section5'
import Section6 from '../section6'
import Section8 from '../section8'
import Summery from '../summery'
import { Footer, NavButton, Navigators, SkipStory } from './style'

const Reveal: any = (window as any).Reveal

class Slides extends PureComponent {
  public sections: Array<RefObject<any>> = []
  public lastMouseWheelStep: number = 0
  public footerRef: RefObject<any>

  constructor (props) {
    super(props)

    for (let i = 1; i <= MAX_SECTION_REF; i++) {
      this.sections[i] = React.createRef()
    }

    this.footerRef = React.createRef()
  }

  public getCurrentSection () {
    const { v } = Reveal.getIndices()
    if (this.sections[v]) {
      return this.sections[v].current
    }

    return null
  }

  public onDocumentMouseScroll (event) {
    if (Date.now() - this.lastMouseWheelStep > 2000) {
      this.lastMouseWheelStep = Date.now()
      const delta = event.detail || -event.wheelDelta

      const target = event.target
      const parentNode = target.parentNode
      if (parentNode.classList.contains('scroll-auto')) {
        if (delta > 0 && parentNode.scrollTop + parentNode.clientHeight < parentNode.scrollHeight) {
          return
        } else if (delta < 0 && parentNode.scrollTop > 0) {
          return
        }
      }

      if (delta > 0) {
        Reveal.next()
      } else if (delta < 0) {
        Reveal.prev()
      }
    }
  }

  public isInFullScreen () {
    const element: any = document

    const fullScreenElement = element.fullscreenElement
      || element.webkitFullscreenElement
      || element.mozFullScreenElement
      || element.msFullscreenElement
      || null

    return !!fullScreenElement
  }

  public canFullScreen () {
    const element: any = document.documentElement

    // Check which implementation is available
    const requestMethod = element.requestFullscreen ||
      element.webkitRequestFullscreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullscreen

    return requestMethod
  }

  public enterFullScreen () {
    const requestMethod = this.canFullScreen()

    if (requestMethod) {
      requestMethod.apply(document.documentElement).catch()
    }
  }

  public exitFullScreen () {
    const el: any = document
    if (el.exitFullscreen) {
      el.exitFullscreen()
    } else if (el.mozCancelFullScreen) { /* Firefox */
      el.mozCancelFullScreen()
    } else if (el.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      el.webkitExitFullscreen()
    } else if (el.msExitFullscreen) { /* IE/Edge */
      el.msExitFullscreen()
    }
  }

  public toggleFullScreen () {
    if (this.isInFullScreen()) {
      this.exitFullScreen()
    } else {
      this.enterFullScreen()
    }
  }

  public play () {
    Reveal.configure({ autoSlide: 8000 })
  }

  public onSkipStoryClick () {
    const last = Reveal.getTotalSlides()
    Reveal.navigateTo(0, last)
  }

  public toggleSkip () {
    let show: boolean = true

    const { v } = Reveal.getIndices()
    const last = Reveal.getTotalSlides()

    if (v === last - 1 || v === 0) {
      show = false
    }

    const display = show ? 'block' : 'none'
    const playback: any = document.getElementsByClassName('playback')[0]

    this.footerRef.current.style.display = display
    if (playback) {
      playback.style.display = display
    }
  }

  public componentDidMount () {
    Reveal.initialize({
      center: true,
      controls: false,
      maxScale: 1,
      minScale: 1,
      progress: true,

      transition: 'slide', // none/fade/slide/convex/concave/zoom

      // More info https://github.com/hakimel/reveal.js#dependencies
      dependencies: []
    })

    Reveal.addEventListener('slidechanged', (event) => {
      // event.previousSlide, event.currentSlide, event.indexh, event.indexv
      const section = this.getCurrentSection()
      if (section) {
        section.onVisible()
      }

      this.toggleSkip()
    })

    Reveal.addEventListener('fragmentshown', (event) => {
      // event.fragment = the fragment DOM element
      const section = this.getCurrentSection()
      if (section) {
        section.onFragmentUpdate()
      }
    })

    Reveal.addEventListener('fragmenthidden', (event) => {
      // event.fragment = the fragment DOM element
      const section = this.getCurrentSection()
      if (section) {
        section.onFragmentUpdate()
      }
    })

    document.addEventListener('DOMMouseScroll', this.onDocumentMouseScroll.bind(this), false) // FF
    document.addEventListener('mousewheel', this.onDocumentMouseScroll.bind(this), false)

    window.addEventListener('resize', () => {
      const section = this.getCurrentSection()
      if (section) {
        section.onVisible()
      }
    })

    this.toggleSkip()
  }

  public render () {
    return (
      <div className='reveal'>
        <div className='slides'>
          <section>
            <section>
              <Home />
            </section>
            <section>
              <Section2 ref={this.sections[1]} isSection={true} />
            </section>
            <section>
              <Section3 ref={this.sections[2]} isSection={true} />
            </section>
            <section>
              <Section4 ref={this.sections[3]} isSection={true} />
            </section>
            <section>
              <Section5 ref={this.sections[4]} isSection={true} />
            </section>
            <section>
              <Section6 ref={this.sections[5]} isSection={true} />
              <span className='fragment'></span>
            </section>
            <section>
              <Section8 ref={this.sections[6]} isSection={true} />
            </section>
            <section>
              <Summery ref={this.sections[7]} />
            </section>
          </section>
        </div>

        <Footer className='slide-footer' ref={this.footerRef}>
          <SkipStory onClick={this.onSkipStoryClick.bind(this)}>
            <span className='middle-text'>Skip Story</span>
          </SkipStory>
          <Navigators>
            {this.canFullScreen() ? (
              <NavButton className='fas fa-expand' onClick={() => { this.toggleFullScreen() }}></NavButton>
            ) : ''}
            <NavButton className='fa fa-play-circle' onClick={() => { this.play() }}></NavButton>
            <NavButton className='fa fa-chevron-circle-up' onClick={() => { Reveal.prev() }}></NavButton>
            <NavButton className='fa fa-chevron-circle-down' onClick={() => { Reveal.next() }}></NavButton>
          </Navigators>
        </Footer>
      </div>
    )
  }
}

export default Slides
