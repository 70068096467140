import React from 'react'
import WaffleChart from '../../common/wafflechart'
import { Colors, sort } from '../../constant'
import connectStore from '../../store/connect'
import Section from '../section'
import { Highlight } from '../section/style'
import { IChartElement, IData, IListElement, ISheetData } from './interface'

class Section5 extends Section {
  public get sheetIndex () {
    return 5
  }

  public processSheetData (sheetData: ISheetData[]): IData {
    const list: IListElement[] = sheetData.map((d: ISheetData) => {
      return {
        gender: d.gender,
        label: d.label,
        source: d.gender,
        value: parseInt(d.deaths, 10)
      }
    })

    sort(list, 'value', true)

    const data = sheetData[0]

    const total = parseInt(data.total, 10)
    const percentage = data.percentage
    const label = data.label

    return { percentage, label, list, total }
  }

  public renderTitle () {
    const data: IData = this.getData()

    return (
      <div>
        <span>{data.label} are most likely to be killed by the police in most cases accounting for</span>
        <Highlight className='box-highlight'> {data.percentage}% </Highlight>
        <span>of the murders or enforced disappearances</span>
      </div>
    )
  }

  public renderChart () {
    const data: IData = this.getData()
    const chartData: IChartElement[] = []

    let count = 0
    const length = this.props.isSection ? 1 : 2
    for (let i = 0; i < length; i++) {
      chartData.push({
        source: this.props.isSection ? data.list[i].gender : data.list[i].label,
        value: data.list[i].value
      })

      count = data.list[i].value
    }

    if (this.props.isSection) {
      chartData.push({
        source: '',
        value: data.total - count
      })
    }

    const colors = [Colors.Red]
    if (this.props.isSection) {
      colors.push(Colors.LightGray)
    } else {
      colors.push(Colors.Black)
    }

    return (
      <WaffleChart
        data={chartData}
        total={data.total}
        colors={colors}
        timeout={this.getTimeOut()}
      />
    )
  }
}

export default connectStore(Section5)
