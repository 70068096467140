import React, { PureComponent } from 'react'
import { AGE } from '../../constant'
import { Highlight } from '../section/style'
import { ITitleProps } from './interface'

class Title extends PureComponent<ITitleProps> {
  public state: {vname: string} = {
    vname: this.props.vname
  }

  public update (vname: string) {
    this.setState({ vname })
  }

  public render () {
    let label: string = ''
    let operation: string = ''
    const { vname } = this.state
    const { labelmost, operationmost } = this.props

    if (vname === AGE) {
      label = labelmost
    } else {
      label = operationmost.percentage + '%'
      operation = operationmost.operation
    }

    return (
      <div>
        <Highlight className='box-highlight'> {label} </Highlight>
        { vname === AGE ? (
          <span>are most likely the ones to be killed by the police and suffer from enforced disappearance</span>
        ) : (
          <span>of those were Killed during {operation}</span>
        )}
      </div>
    )
  }
}

export default Title
