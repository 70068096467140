import Axios from 'axios'

const spreadsheetId = '1VLxyGWdqTIpTPspmNPAkovzEJ0fEnUg_lxWoAdAgvYM'

export default Axios.create({
  baseURL: 'https://spreadsheets.google.com/feeds/list/' + spreadsheetId
})

export const apiV4Url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:csv&sheet=`
export const sheetNames = [
  '', // DO NOT CHANGE THIS
  'DataSheet',
  'Year',
  'Manner',
  'Charged',
  'Gender',
  'Age',
  'Operation',
  'Region'
]