import React, { PureComponent, RefObject } from 'react'

import { AGE, MAX_SECTION_REF, OPERATION } from '../../constant'
import Section2 from '../section2'
import Section3 from '../section3'
import Section5 from '../section5'
import Section6 from '../section6'
import Section8 from '../section8'

import { BoxTab, Footer, FooterA } from './style'

class Summery extends PureComponent {
  public state = {
    currentIndex: 0
  }

  public sections: Array<RefObject<any>> = []

  constructor (props) {
    super(props)

    for (let i = 1; i <= MAX_SECTION_REF; i++) {
      this.sections[i] = React.createRef()
    }
  }

  public onVisible () {
    if (this.state.currentIndex) {
      const section = this.sections[this.state.currentIndex].current
      section.onVisible()
    } else {
      this.setState({ currentIndex: 1 })
    }
  }

  public render () {
    if (!this.state.currentIndex) {
      return ''
    }

    return (
      <>
        <div className='box-tabs'>
          <div>
            <BoxTab
              isActive={this.state.currentIndex === 1}
              onClick={() => this.setState({ currentIndex: 1 })}>
              Year
            </BoxTab>
            <BoxTab
              isActive={this.state.currentIndex === 2}
              onClick={() => this.setState({ currentIndex: 2 })}>
              Location
            </BoxTab>
            <BoxTab
              isActive={this.state.currentIndex === 3}
              onClick={() => this.setState({ currentIndex: 3 })}>
              Age
            </BoxTab>
            <BoxTab
              isActive={this.state.currentIndex === 4}
              onClick={() => this.setState({ currentIndex: 4 })}>
              Sex
            </BoxTab>
          </div>
          <div>
            <BoxTab
              isActive={this.state.currentIndex === 5}
              onClick={() => this.setState({ currentIndex: 5 })}>
              Operation
            </BoxTab>
            <BoxTab
              isActive={this.state.currentIndex === 6}
              onClick={() => this.setState({ currentIndex: 6 })}>
              Manner of Death
            </BoxTab>
          </div>
        </div>

        <>
          {this.state.currentIndex === 1 ?
            <Section2 ref={this.sections[1]} />
          : ''}
          {this.state.currentIndex === 2 ?
            <Section8 ref={this.sections[2]} />
          : ''}
          {this.state.currentIndex === 3 ?
            <Section6 ref={this.sections[3]} vname={AGE} />
          : ''}
          {this.state.currentIndex === 4 ?
            <Section5 ref={this.sections[4]} />
          : ''}
          {this.state.currentIndex === 5 ?
            <Section6 ref={this.sections[5]} vname={OPERATION} />
          : ''}
          {this.state.currentIndex === 6 ?
            <Section3 ref={this.sections[6]} />
          : ''}
        </>

        <Footer>
          <FooterA target='_blank' href='https://www.odipodev.com/'>Designed and Developed by Odipo Dev</FooterA>
        </Footer>
      </>
    )
  }
}

export default Summery
