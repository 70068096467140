import React from 'react'
import { Provider } from 'react-redux'
import { combineReducers, createStore } from 'redux'
import gsheet from './store/gsheet/reducers'

import './App.css'
import Slides from './components/slides'

import styled from 'styled-components'
import { Colors } from './constant'

const reducers = combineReducers({
  gsheet
})

const Topbar = styled.div`
  background: ${Colors.Black};
  height: 50px;
  position: relative;
`

const WhiteA = styled.a`
  color: ${Colors.White};
  text-decoration: none;
	margin: 0 10px;
`

const App: React.FC = () => {
  return (
    <Provider store={createStore(reducers)}>
      <div className='App'>
        <Topbar>
          <a target='_blank' href='https://missingvoices.or.ke'>
            <div className='logo'></div>
          </a>
          <div className='menu'>
            <WhiteA target='_blank' href='https://gallery.missingvoices.or.ke'>Gallery</WhiteA>
            <WhiteA target='_blank' href='https://missingvoices.or.ke/about-us/'>Methodology</WhiteA>
          </div>
        </Topbar>

        <Slides />
      </div>
    </Provider>
  )
}

export default App
