import styled from 'styled-components'
import { Colors } from '../../constant'

export const BoxTitle = styled.div`
  position: absolute;
  top: 25px;
`

export const BoxChart = styled.div`
  position: absolute;
  bottom: 100px;
`

export const Highlight = styled.span`
  color: ${Colors.Red};
  margin: 0 5px;
  font-weight: bold;
`

export const BlackSpan = styled.span`
  color: ${Colors.Black};
`

export const DarkGreySpan = styled.span`
  color: ${Colors.DarkGrey};
`

export const RedA = styled.a`
  color: ${Colors.Red};
  font-size: 16px;
  text-decoration: none;
`
