import React, { RefObject } from 'react'
import BubbleChart from '../../common/bubblechart'
import { AGE, Colors, OPERATION, OTHER, sort, UNKNOWN } from '../../constant'
import connectStore from '../../store/connect'
import Section from '../section'
import { IData, IList1Element, IList2Element, IListElement, ISheetData1, ISheetData2 } from './interface'
import Title from './title'

const Reveal: any = (window as any).Reveal

class Section6 extends Section {
  public chartRef: RefObject<any>
  public titleRef: RefObject<any>

  constructor (props) {
    super(props)

    this.chartRef = React.createRef()
    this.titleRef = React.createRef()
  }

  public get sheetIndex () {
    return 6
  }

  public async loadData () {
    const data1 = await this.getDataFromSheet(this.sheetIndex)
    const data2 = await this.getDataFromSheet(this.sheetIndex + 1)

    const data = this.processSheetData(data1, data2)
    const { gsheetActions } = this.props

    gsheetActions.setData(this.sheetIndex, data)
  }

  public processSheetData (sheetData1: ISheetData1[], sheetData2: ISheetData2[]): IData {
    const list1: IList1Element[] = sheetData1.map((d: ISheetData1) => {
      return {
        age: d.age,
        deaths: parseInt(d.deaths, 10),
        dots: parseInt(d.dots, 10),
        label: d.label
      }
    })

    const list2: IList2Element[] = sheetData2.map((d: ISheetData2) => {
      return {
        deaths: parseInt(d.deaths, 10),
        dots: parseInt(d.dots, 10),
        label: d.label,
        operation: d.operation,
        percentage: d.percentage
      }
    })

    sort(list2, 'deaths', true)
    const operationmost = list2[0]

    const data = sheetData1[0]

    const totaldots = parseInt(data.totaldots, 10)
    const total = parseInt(data.total, 10)
    const deathperdot = parseInt(data.deathperdot, 10)
    const labelmost = data.labelmost

    const getLegend = (prop: string, count: number) => {
      return prop + ' (' + count + ')'
    }

    const colors: { [key: string]: string } = {}
    const colorsInSection: { [key: string]: string } = {}

    const ageList: string[] = []
    let count1 = 0
    for (const e of list1) {
      const legend = getLegend(e.age, e.deaths)
      for (let i = 0; i < e.dots; i++) {
        ageList.push(legend)
      }

      colorsInSection[legend] = e.label === data.labelmost ? Colors.Red : Colors.DarkGrey
      colors[legend] = Colors.Red
      count1 += e.deaths
    }

    const operationList: string[] = []
    let count2 = 0
    for (const e of list2) {
      const legend = getLegend(e.operation, e.deaths)
      for (let i = 0; i < e.dots; i++) {
        operationList.push(legend)
      }

      colorsInSection[legend] = count2 === 0 ? Colors.Red : Colors.DarkGrey
      colors[legend] = Colors.Red
      count2 += e.deaths
    }

    const unknown = getLegend(UNKNOWN, total - count1)
    const other = getLegend(OTHER, total - count2)

    colorsInSection[unknown] = Colors.DarkGrey
    colorsInSection[other] = Colors.DarkGrey

    colors[unknown] = Colors.Red
    colors[other] = Colors.Red

    const list: IListElement[] = []
    for (let i = 0; i < totaldots; i++) {
      list.push({
        age: ageList[i] || unknown,
        operation: operationList[i] || other
      })
    }

    list.reverse()

    return { deathperdot, list, labelmost, operationmost, colors, colorsInSection }
  }

  public renderTitle () {
    const data: IData = this.getData()
    const vname = this.getVName()

    return (
      <Title
        ref={this.titleRef}
        vname={vname}
        labelmost={data.labelmost}
        operationmost={data.operationmost}
      />
    )
  }

  public getVName () {
    if (this.props.vname) {
      return this.props.vname
    }

    const { f } = Reveal.getIndices()

    if (f === -1) {
      return AGE
    } else {
      return OPERATION
    }
  }

  public onFragmentUpdate () {
    this.chartRef.current.chart.draw(this.getVName())
    this.titleRef.current.update(this.getVName())
  }

  public renderChart () {
    const data: IData = this.getData()

    return (
      <BubbleChart
        ref={this.chartRef}
        data={data.list}
        deathperdot={data.deathperdot}
        colors={this.props.isSection ? data.colorsInSection : data.colors}
        vname={this.getVName()}
        timeout={this.getTimeOut()}
        showLegend={!this.props.isSection}
      />
    )
  }
}

export default connectStore(Section6)
