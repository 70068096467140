import * as types from './types'

export function action (type: string, payload: any) {
  if (payload !== null || payload !== undefined) {
    return { type, payload }
  }

  return { type }
}

export const setData = (index: number, data: any[]) => {
  return action(types.SET_DATA, { index, data })
}
