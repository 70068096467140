import React from 'react'
import WaffleChart from '../../common/wafflechart'
import { Colors, OTHER, sort } from '../../constant'
import connectStore from '../../store/connect'
import Section from '../section'
import { BlackSpan, DarkGreySpan, Highlight } from '../section/style'
import { IData, IListElement, ISheetData } from './interface'

class Section3 extends Section {
  public get sheetIndex () {
    return 3
  }

  public processSheetData (sheetData: ISheetData[]): IData {
    const list: IListElement[] = sheetData.map((d: ISheetData) => {
      return {
        source: d.manner,
        value: parseInt(d.deaths, 10)
      }
    })

    sort(list, 'value', true)

    const data = sheetData[0]

    const total = parseInt(data.total, 10)
    const percentage = data.percentage
    const labelhighest = data.labelhighest
    const labelelse: string[] = []

    for (let i = 1; i < Math.min(3, sheetData.length); i++) {
      if (list[i].value > 0) {
        labelelse.push(sheetData[i].labelelse)
      }
    }

    return { list, percentage, labelhighest, labelelse, total }
  }

  public renderTitle () {
    const data: IData = this.getData()

    return (
      <div>
        <Highlight className='box-highlight'>{data.percentage}%</Highlight> of them were {data.labelhighest}.

        { data.labelelse[0] ?
          <BlackSpan> {data.labelelse[0]} </BlackSpan>
        : ''}

        { data.labelelse[1] ?
          <>
            <span>and</span>
            <DarkGreySpan> {data.labelelse[1]} </DarkGreySpan>
          </>
        : ''}

        accounted for most other deaths
      </div>
    )
  }

  public renderChart () {
    const data: IData = this.getData()
    const chartData: IListElement[] = []

    let count = 0

    data.list.forEach((d) => {
      chartData.push({
        source: d.source,
        value: d.value
      })
      count += d.value
    })

    chartData.push({
      source: OTHER,
      value: data.total - count
    })

    const colors = [Colors.Red, Colors.Black, Colors.DarkGrey, Colors.LightGray]

    return (
      <WaffleChart
        data={chartData}
        total={data.total}
        colors={colors}
        timeout={this.getTimeOut()}
      />
    )
  }
}

export default connectStore(Section3)
