import styled from 'styled-components'
import { Colors } from '../../constant'

export const Footer = styled.div`
  position: absolute;
  left: 10px;
  bottom: 60px;
  right: 10px;
  z-index: 1;
`

export const SkipStory = styled.div`
  float: left;
  background: ${Colors.Black};
  color: ${Colors.White};
  font-size: 20px;
  border-radius: 18px;
  padding: 5px 15px;
  cursor: pointer;
  outline: none;
  z-index: 1;
  position: relative;
  height: 32px;
  width: 130px;
`

export const Navigators = styled.div`
  float: right;
`

export const NavButton = styled.i`
  cursor: pointer;
  width: 36px;
  font-size: 36px;
  font-weight: bold;
`
