import React, { PureComponent, RefObject } from 'react'

import { Box100 } from '../style'

import renderChart from './chart.js'
import { IChartProps } from './interface'
import './style.css'

class WaffleChart extends PureComponent<IChartProps> {
  public myRef: RefObject<any>

  constructor (props: IChartProps) {
    super(props)
    this.myRef = React.createRef()
  }

  public componentDidMount () {
    if (this.props.data.length === 0) {
      return
    }

    setTimeout(() => {
      if (!this.myRef.current) {
        return
      }

      const legendHeight = 120
      const gap = 2

      const width = this.myRef.current.clientWidth
      const height = this.myRef.current.clientHeight - legendHeight

      const area = Math.floor((height * width) / this.props.total)
      const circleSize = Math.sqrt(area) - gap

      const cols = Math.floor(width / (circleSize + gap))
      const rows = Math.ceil(this.props.total / cols)

      renderChart()
        .selector(this.myRef.current)
        .data(this.props.data.map((x) => Object.assign({}, x)))
        // .useWidth(true)
        // .label("Value of producers' sales in 2013, in thousands of dollars")
        .size(circleSize)
        .gap(gap)
        .rows(rows)
        .columns(cols)
        .rounded(true)
        .colors(this.props.colors)
        .hideLegend(this.props.hideLegend)()
    }, this.props.timeout)
  }

  public render () {
    return (
      <Box100 ref={this.myRef}></Box100>
    )
  }
}

export default WaffleChart
