export default function renderChart(el, data, colors) {
  var d3 = window.d3;
  var _ = window._;
  
  var width = Math.min(el.clientHeight, el.clientWidth),
    height = Math.min(el.clientHeight, el.clientWidth);

  d3.select(el).selectAll("svg").remove()

  var svg = d3.select(el).append("svg")
      .attr("width", width)
      .attr("height", height);

  for (var j = 0; j < data.length; j++) {
    data[j].radius = (width / 80);
    data[j].x = Math.random() * width;
    data[j].y = Math.random() * height;
  }

  var padding = 2;
  var maxRadius = d3.max(_.pluck(data, 'radius'));

  var getCenters = function (vname, size) {
    var counts = _.countBy(data, function (obj) {
      return obj[vname];
    });

    var centers, map;
    centers = _.uniq(_.pluck(data, vname)).map(function (d, i) {
      return {name: d, value: 1, count: counts[d] };
    });
    map = d3.layout.treemap().size(size).ratio(1/1);
    map.nodes({children: centers});

    return centers;
  };

  var nodes = svg.selectAll("circle")
    .data(data);

  nodes.enter().append("circle")
    .attr("class", "node")
    .attr("cx", function (d) { return d.x; })
    .attr("cy", function (d) { return d.y; })
    .attr("r", function (d) { return d.radius; })
    // .style("fill", function (d) { return colors[0]; })
    // .on("mouseover", function (d) { showPopover.call(this, d); })
    // .on("mouseout", function (d) { removePopovers(); })

  var force = d3.layout.force();

  this.draw = function(varname) {
    var centers = getCenters(varname, [width, height]);
    force.on("tick", tick(centers, varname));
    labels(centers, varname)
    force.start();
  }

  function tick (centers, varname) {
    var foci = {};
    for (var i = 0; i < centers.length; i++) {
      foci[centers[i].name] = centers[i];
    }
    return function (e) {
      for (var i = 0; i < data.length; i++) {
        var o = data[i];
        var f = foci[o[varname]];
        o.color = colors[o[varname]]
        o.y += ((f.y + (f.dy / 2)) - o.y) * e.alpha;
        o.x += ((f.x + (f.dx / 2)) - o.x) * e.alpha;
      }
      nodes.each(collide(0.11))
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .style("fill", function (d) { return d.color; })
    }
  }

  function labels (centers, varname) {
    svg.selectAll(".label").remove();

    var text = svg.selectAll(".label")
      .data(centers).enter().append("text")
      .attr("class", "label")
      // .text(function (d) { return d.name })
      .attr("text-anchor", "middle")
      .style("font-size", function(d, i) {
        if(varname === 'age') {
          return width / 30;
        } else {
          return width / 40;
        }
      })
      .attr("transform", function (d, i) {
        var x = d.x + (d.dx / 2);
        var y = d.y;

        var names = d.name.split(" ")
        if(names.length > 2) {
          y -= (width / 80)
        }
        return "translate(" + x + ", " + y + ")";
      });
    
    text.selectAll("tspan.text")
      .data((d) => {
        var names = d.name.split(" ")
        if(names.length === 2) {
          return [d.name]
        }

        var a = names.pop()
        var b = names.pop()

        names.push(b + ' ' + a)
        return names
      })
      .enter()
      .append("tspan")
      .attr("text-anchor", "middle")
      .attr("class", "text")
      .text(d => d)
      .attr("x", 10)
      .attr("dy", width / 30);
  }

  // function removePopovers () {
  //   $('.popover').each(function() {
  //     $(this).remove();
  //   }); 
  // }

  // function showPopover (d) {
  //   $(this).popover({
  //     placement: 'auto top',
  //     container: 'body',
  //     trigger: 'manual',
  //     html : true,
  //     content: function() { 
  //       return "Make: " + d.make + "<br/>Model: " + d.model + 
  //               "<br/>Trans: " + d.trans + "<br/>MPG: " + d.comb; 
  //     }
  //   });
  //   $(this).popover('show')
  // }

  function collide(alpha) {
    var quadtree = d3.geom.quadtree(data);
    return function (d) {
      var r = d.radius + maxRadius + padding,
          nx1 = d.x - r,
          nx2 = d.x + r,
          ny1 = d.y - r,
          ny2 = d.y + r;
      quadtree.visit(function(quad, x1, y1, x2, y2) {
        if (quad.point && (quad.point !== d)) {
          var x = d.x - quad.point.x,
              y = d.y - quad.point.y,
              l = Math.sqrt(x * x + y * y),
              r = d.radius + quad.point.radius + padding;
          if (l < r) {
            l = (l - r) / l * alpha;
            d.x -= x *= l;
            d.y -= y *= l;
            quad.point.x += x;
            quad.point.y += y;
          }
        }
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    };
  }
  
}