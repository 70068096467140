import React from 'react'
import WaffleChart from '../../common/wafflechart'
import { Colors } from '../../constant'
import connectStore from '../../store/connect'
import Section from '../section'
import { Highlight, RedA } from '../section/style'
import { IData, IListElement, ISheetData } from './interface'

const CHARGED = 'charged'

class Section4 extends Section {
  public get sheetIndex () {
    return 4
  }

  public processSheetData (sheetData: ISheetData[]): IData {
    const data = sheetData[0]

    const source = CHARGED
    const value = parseInt(data.charged, 10)
    const total = parseInt(data.total, 10)
    return { source, value, total }
  }

  public renderTitle () {
    const data: IData = this.getData()

    return (
      <>
        <div>
          <span>Of all cases identified only</span>
          <Highlight className='box-highlight'> {data.value} </Highlight>
          <span>have been charged with a crime for these cases</span>
        </div>
        <div>
          <RedA target='_blank' href='https://gallery.missingvoices.or.ke'>View All Cases</RedA>
        </div>
      </>
    )
  }

  public renderChart () {
    const data: IData = this.getData()
    const chartData: IListElement[] = []

    chartData.push({
      source: CHARGED,
      value: data.value
    })

    chartData.push({
      source: '',
      value: data.total - data.value
    })

    const colors = [Colors.Red, Colors.LightGray]

    return (
      <WaffleChart
        data={chartData}
        total={data.total}
        colors={colors}
        hideLegend={true}
        timeout={this.getTimeOut()}
      />
    )
  }
}

export default connectStore(Section4)
