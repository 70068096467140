export default function renderChart(el, data, max, colors, showLegend, barHeight) {
  var d3 = window.d3;
  var yAxisWidth = 100;
  var yAxisMarginRight = 30;
  var labelOffset = 5;
  var duration = 800;
  var delay = 100;

  //set up svg using margin conventions - we'll need plenty of room on the left for labels
  var margin = {
    top: 15,
    right: 50,
    bottom: 60,
    left: 60
  };

  var width = el.clientWidth - margin.left - margin.right,
    height = el.clientHeight - margin.top - margin.bottom;

  if(barHeight) {
    height = barHeight * data.length
  }

  d3.select(el).selectAll("svg").remove()

  var svg = d3.select(el).append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var x = d3.scale.linear()
    .range([0, width - yAxisWidth])
    .domain([0, max]);

  var y = d3.scale.ordinal()
    .rangeRoundBands([0, height], .2)
    .domain(data.map(function (d) {
        return d.county;
    }));

  //make y axis to show bar names
  var yAxis = d3.svg.axis()
    .scale(y)
    //no tick marks
    .tickSize(0)
    .orient("left");

  var gy = svg.append("g")
    .attr("transform", "translate(" + (yAxisWidth - yAxisMarginRight) + ", 0)")
    .call(yAxis)

  var bars = svg.selectAll(".bar")
    .data(data)
    .enter()
    .append("g")

  // Create Event Handlers for mouse
  function handleMouseEvent(el, d, isOver) {  // Add interactivity
    if(!showLegend) {
      return;
    }

    var index = isOver ? 1 : 0
    // Use D3 to select element, change color and size
    d3.select(el).attr("fill", colors[index]);
    d3.selectAll("#t" + d.county).style("opacity", index)
  }

  //append rects
  bars.append("rect")
    .attr("y", function (d) {
      return y(d.county);
    })
    .attr("height", y.rangeBand())
    .attr("x", yAxisWidth)
    .attr("width", function (d) {
      return 0;
    })
    .attr("fill", function(d, i) {
      if(showLegend || i === 0) {
        return colors[0];
      } else {
        return colors[1];
      }
    })
    .on("mouseover", function(d) { handleMouseEvent(this, d, true) })
    .on("mouseout", function(d) { handleMouseEvent(this, d, false) });

  // Animation
  svg.selectAll("rect")
    .transition()
    .duration(duration)
    .attr("width", function(d) { return x(d.deaths) })
    .delay(function(d,i) { return(i * delay) })

  //add a value label to the right of each bar
  bars.append("text")
    .attr("class","label")
    //y position of the label is halfway down the bar
    .attr("y", function (d) {
      return y(d.county) + (y.rangeBand() / 2) + labelOffset;
    })
    //x position is 3 pixels to the right of the bar
    .attr("x", function (d) {
      return x(0) + yAxisWidth + labelOffset;
    })
    .text(function (d) {
      return d.deaths;
    })
    .attr("id", function(d) { return "t" + d.county })
    .style("opacity", showLegend ? 0 : 1);

  svg.selectAll(".label")
    .transition()
    .duration(800)
    .attr("x", function(d) { return x(d.deaths) + yAxisWidth + labelOffset })
    .delay(function(d,i) { return (i * delay) })

  d3.selectAll(".tick")
    .style("font-size", "20px")
    .style("font-weight", "bold")

  if(!showLegend) {
    return Math.ceil(y.rangeBand() / 0.8)
  }

  // now add titles to the axes
  svg.append("text")
    .style("font-size", "20px")
    .style("font-weight", "bold")
    .attr("text-anchor", "left")  // this makes it easy to centre the text as the transform is applied to the anchor
    .attr("transform", "translate(" + yAxisWidth + ",0)")  // text is drawn off the screen top left, move down and out and rotate
    .text("Cases");

  svg.append("text")
    .style("font-size", "20px")
    .style("font-weight", "bold")
    .style("fill", colors[2])
    .attr("text-anchor", "left")  // this makes it easy to centre the text as the transform is applied to the anchor
    .attr("transform", "translate(0, 0)")  // centre below axis
    .text("County");

  return Math.ceil(y.rangeBand() / 0.8)
}