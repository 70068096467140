import React, { PureComponent, RefObject } from 'react'

import { Box100 } from '../style'
import renderChart from './chart.js'
import { IChartProps } from './interface'

class BarChart extends PureComponent<IChartProps> {
  public myRef: RefObject<any>

  constructor (props: IChartProps) {
    super(props)
    this.myRef = React.createRef()
  }

  public componentDidMount () {
    if (this.props.data.length === 0) {
      return
    }

    setTimeout(() => {
      if (!this.myRef.current) {
        return
      }

      renderChart(this.myRef.current, this.props.data, this.props.max, this.props.colors, this.props.showLegend)
    }, this.props.timeout)
  }

  public render () {
    return (
      <Box100 ref={this.myRef}></Box100>
    )
  }
}

export default BarChart
