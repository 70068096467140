export const MAX_SECTION_REF = 7
export const UNKNOWN = 'Unknown'
export const OTHER = 'Other'
export const AGE = 'age'
export const OPERATION = 'operation'

export const Colors = {
  Black: '#2A2A2A',
  DarkGrey: '#7D7D7D',
  LightGray: '#D7DDE0',
  Red: '#A41014',
  White: '#FFFFFF'
}

export const sort = (list: any[], prop: string, isDescending: boolean = false) => {
  list.sort((a, b) => a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0)
  if (isDescending) {
    list.reverse()
  }
}
