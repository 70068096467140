import React, { PureComponent } from 'react'
import Api, { apiV4Url, sheetNames } from '../../api'

import { IProps } from '../../interface'
import { BoxChart, BoxTitle } from './style'

declare const d3: any;

const TEXT = '$t'
const KEY_PREFIX = 'gsx$'

abstract class Section extends PureComponent<IProps> {
  public state = {
    key: 0
  }

  public abstract renderChart()

  public abstract renderTitle()

  public abstract processSheetData(sheetData: any[], sheetData2?: any[]): any

  public abstract get sheetIndex()

  // public async getDataFromSheet(index: number) {
  //   d3.csv(`${apiV4Url}Year`, function (data) {
  //     for (var i = 0; i < data.length; i++) {
  //       console.log(data[i]);
  //     }
  //   });

  //   const response = await Api.get('/' + index + '/public/values', {
  //     params: { alt: 'json' }
  //   })

  //   const sheetData: any[] = []
  //   response.data.feed.entry.forEach((obj) => {
  //     const dataObj: any = {}
  //     let prop: string = ''

  //     for (const key of Object.keys(obj)) {
  //       prop = key.replace(KEY_PREFIX, '')
  //       if (key !== prop) {
  //         dataObj[prop] = obj[key][TEXT]
  //       }
  //     }

  //     sheetData.push(dataObj)
  //   })

  //   return sheetData
  // }

  public async getDataFromSheet(index: number) {
    return new Promise<any[]>((resolve) => {
      d3.csv(`${apiV4Url}${sheetNames[index]}`, data => {
        resolve(data.map(d => {
          const d1: any = {};
          Object.keys(d).forEach(k => {
            d1[k.toLowerCase()] = d[k];
          });
          return d1;
        }))
      });
    })
  }

  public async loadData() {
    const sheetData = await this.getDataFromSheet(this.sheetIndex)

    const data = this.processSheetData(sheetData)
    const { gsheetActions } = this.props

    gsheetActions.setData(this.sheetIndex, data)
  }

  public async onVisible() {
    const data = this.getData()
    if (!data) {
      await this.loadData()
    }

    this.setState({ key: Math.random() })
  }

  public onFragmentUpdate() {
    /**
     * do nothing here
     * override in child class
     */
  }

  public getData() {
    const { sheets } = this.props.gsheetState
    return sheets[this.sheetIndex]
  }

  public getTimeOut() {
    return this.props.isSection ? 800 : 0
  }

  public componentDidMount() {
    const data = this.getData()
    if (!data) {
      this.loadData().catch()
    }
  }

  public render() {
    if (!this.state.key && this.props.isSection) {
      return ''
    }

    const data = this.getData()
    return (
      <>
        {data && this.props.isSection ? (
          <BoxTitle className='box-title'>
            {this.renderTitle()}
          </BoxTitle>
        ) : ''}

        {data ? (
          <BoxChart className='box-chart' key={this.state.key}>
            {this.renderChart()}
          </BoxChart>
        ) : ''}
      </>
    )
  }
}

export default Section
