import React, { PureComponent } from 'react'

import './index.css'
import { Mousey, Scroller, ScrollInfo } from './style'

import animation from './animation'
import './animation/style.css'

class Home extends PureComponent {

  public componentDidMount () {
    animation()
  }

  public render () {
    return (
      <div className='background'>
        <div className='cd-intro'>
          <h1 className='cd-headline clip is-full-width'>
          <span><b>Missing Voices is on a Mission to End</b></span>
            <span className='cd-words-wrapper'>
              <b className='is-hidden'>Police Killings</b>
              <b className='is-visible'>Enforced Disappearances</b>
            </span>
          </h1>
        </div>
        <div className='scroll-downs'>
          <Mousey>
            <Scroller></Scroller>
          </Mousey>
          <ScrollInfo>
            <div> Scroll On To </div>
            <div> Discover More </div>
          </ScrollInfo>
        </div>
      </div>
    )
  }
}

export default Home
