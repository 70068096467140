import { ActionType } from 'typesafe-actions'
import * as actions from '../actions'
import * as types from '../actions/types'
import { IGSheetState } from '../interface'

const initialState: IGSheetState = {
  sheets: []
}

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  const newState: IGSheetState = state
  switch (action.type) {
    case types.SET_DATA:
      const { index, data } = action.payload
      newState.sheets[index] = data
      break

    default:
      break
  }

  return Object.assign({}, state, newState)
}

export default reducer
