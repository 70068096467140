import styled from 'styled-components'
import { Colors } from '../../constant'

export const Scroller = styled.div`
  background-color: ${Colors.White}
  width: 3px;
  height: 10px;
  border-radius: 25%;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
`

export const ScrollInfo = styled.div`
  color: ${Colors.White}
  margin-top: 10px;
  line-height: 24px;
`

export const Mousey = styled.div`
  border: 2px solid ${Colors.White};
  width: 3px;
	width: 3px;
  padding: 10px;
  height: 14px;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  margin: auto;
`
