import styled, { css } from 'styled-components'
import { Colors } from '../../constant'

interface ITabProps {
  isActive: boolean
}

export const BoxTab = styled.div<ITabProps>`
  display: inline-block;
  margin: 10px;
  padding: 10px 15px;
  border-radius: 18px;
  cursor: pointer;
  border: 2px solid ${Colors.Black}
  ${(props) => {
    return props.isActive &&
      css`
      background: ${Colors.Black}
      color: ${Colors.White}
    `
  }}
`

export const Footer = styled.div`
  background: ${Colors.Black};
  position: absolute;
  left: 0;
  bottom: 50px;
  right: 0;
  z-index: 1;
  padding: 12px;
`

export const FooterA = styled.a`
  color: ${Colors.White};
  text-decoration: none;
`
