import React, { PureComponent, RefObject } from 'react'

import styled from 'styled-components'
import { Colors } from '../../constant'
import { Box100 } from '../style'
import renderChart from './chart.js'
import { IChartProps } from './interface'

const ViewMore = styled.div`
  color: ${Colors.DarkGrey};
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
`

const Icon = styled.i`
  color: ${Colors.LightGray}
  font-size: 42px;
  margin-right: 8px;
  vertical-align: text-bottom;
  font-weight: bold;
  height: 30px;
  margin-right: 10px;
  padding-right: 5px;
`

const Reveal: any = (window as any).Reveal

class HBarChart extends PureComponent<IChartProps> {
  public myRef: RefObject<any>
  public barHeight: number = 0
  public lastMouseWheelStep: number = 0
  public lastScrollTop: number = 0

  public state = {
    viewMore: false
  }

  constructor (props: IChartProps) {
    super(props)
    this.myRef = React.createRef()
  }

  public updateElData (preventSwipe: boolean) {
    if (preventSwipe) {
      this.myRef.current.setAttribute('data-prevent-swipe', 'true')
    } else {
      this.myRef.current.removeAttribute('data-prevent-swipe')
    }
  }

  public onDocumentMouseScroll (event) {
    if (Date.now() - this.lastMouseWheelStep > 2000) {
      const target = event.target
      const nowScrollTop = target.scrollTop
      const delta = nowScrollTop - this.lastScrollTop

      this.lastScrollTop = nowScrollTop

      if (delta > 0 && target.scrollTop + target.clientHeight < target.scrollHeight) {
        this.updateElData(true)
      } else if (delta < 0 && target.scrollTop > 0) {
        this.updateElData(true)
      }

      if (delta > 0) {
        this.updateElData(false)
      } else if (delta < 0) {
        this.updateElData(false)
      }
    }

    this.lastMouseWheelStep = Date.now()
  }

  public componentDidMount () {
    if (this.props.data.length === 0) {
      return
    }

    setTimeout(() => {
      if (!this.myRef.current) {
        return
      }

      this.myRef.current.addEventListener('scroll', this.onDocumentMouseScroll.bind(this), false)

      const data = this.props.data.slice(0, 6)
      this.barHeight = renderChart(this.myRef.current, data, this.props.max, this.props.colors, this.props.showLegend)
    }, this.props.timeout)
  }

  public componentDidUpdate (prevProps, prevState) {
    if (this.props.data.length === 0) {
      return
    }

    if (this.state.viewMore === prevState.viewMore) {
      return
    }

    this.updateElData(this.state.viewMore)

    const data = this.state.viewMore ? this.props.data : this.props.data.slice(0, 6)
    renderChart(this.myRef.current, data, this.props.max, this.props.colors, this.props.showLegend, this.barHeight)
  }

  public render () {
    return (
      <>
      <Box100 ref={this.myRef} className={this.state.viewMore ? 'scroll-auto' : ''}></Box100>
      {!this.state.viewMore ? (
        <ViewMore onClick={() => { this.setState({ viewMore: true }) }}>
          <Icon className='fa fa-angle-down'></Icon>
          View More Counties
        </ViewMore>
      ) : ''}
      </>
    )
  }
}

export default HBarChart
