import React, { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../gsheet/actions'
import { IState } from '../interface'

const connectStore: any = (component: ComponentType<any>) => {
  return connect((state: IState) => ({
    gsheetState: state.gsheet
  }),
  (dispatch: any) => ({
    gsheetActions: bindActionCreators(actions, dispatch)
  }), null, { forwardRef: true })(component)
}

export default connectStore
