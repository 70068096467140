import React from 'react'
import BarChart from '../../common/barchart'
import { Colors } from '../../constant'
import connectStore from '../../store/connect'
import Section from '../section'
import { Highlight } from '../section/style'
import { IData, IListElement, ISheetData } from './interface'

class Section2 extends Section {
  public get sheetIndex() {
    return 2
  }

  public processSheetData(sheetData: ISheetData[]): IData {
    const list: IListElement[] = sheetData.map((d: ISheetData) => {
      return {
        deaths: parseInt(d.deaths, 10),
        year: d.year
      }
    })

    const max = parseInt(sheetData[0].max, 10)
    const total = parseInt(sheetData[0].total, 10)
    return { list, max, total }
  }

  public renderTitle() {
    const data: IData = this.getData()

    return (
      <div>
        <span>According to our data,</span>
        <Highlight className='box-highlight'> {data.total} </Highlight>
        <span>People have been killed by the police or reported missing since 2007</span>
      </div>
    )
  }

  public renderChart() {
    const data: IData = this.getData()

    return (
      <BarChart
        colors={[Colors.Red, Colors.Black]}
        data={data.list}
        max={data.max}
        timeout={this.getTimeOut()}
        showLegend={!this.props.isSection}
      />
    )
  }
}

export default connectStore(Section2)
